<app-header xmlns="http://www.w3.org/1999/html"></app-header>
<section>
  <div class="container-fluid h-custom">
    <div class="row d-flex justify-content-center align-items-center h-100">

      <!-- chamado -->
      <div class="d-flex justify-content-center">
        <tr>
          <td *ngIf="!telebrasiliaLogado">
            <form (ngSubmit)="novo()" *ngIf="showNovoChamado">
              <div class="text-primary pt-4">
                <button type="submit" class="btn btn-success btn float-right"
                  style="padding-left: 2.5rem; padding-right: 2.5rem; margin-right: 100px">
                   Adicionar</button>
              </div>
            </form>
          </td>
          <td>
            <form (ngSubmit)="consultarChamado()" *ngIf="showNovoChamado">
              <div class="text-primary pt-4">
                <button type="submit" class="btn btn-success btn float-right"
                  style="padding-left: 2.5rem; padding-right: 2.5rem;">
                  Consultar</button>
              </div>
            </form>
          </td>
        </tr>
      </div>
      <div class="col-md-8 col-lg-6 col-xl-8 offset-xl-0">
        <br>
        <div class="form-outline mb-4" *ngIf="showLoading">
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
            </div>
          </div>
        </div>


        <!-- Criar chamado -->
        <form [formGroup]="chamadoForm" (ngSubmit)="criar()" method="post" enctype="multipart/form-data"
          *ngIf="showFormChamado">

          <div class="form-outline mb-2">
            <h6> <label for="tpChamado" class="form-label text-primary">Tipo do chamado</label></h6>
            <select id="tpChamado" class="form-select" formControlName="tpChamado" (ngModelChange)="setCleanTipo()">
              <option>Sem acesso</option>
              <option>Acesso lento</option>
              <option>Mudança de plano</option>
              <option>Mudança de endereço</option>
              <option>Outros assuntos</option>
            </select>
          </div>

         <label class="text-danger"><strong> {{tipo}} </strong></label>

        <div [hidden]="empresaDadosad.length <= 0 && !empresaDadosad">
          <div class="form-outline mb-2">
            <h6> <label for="idEmprad" class="form-label text-primary">Localidade afetada</label></h6>
            <select id="idEmprad" class="form-select" formControlName="idEmprad" (ngModelChange)="selecionarLocalidade($event)">
              <option *ngFor="let dadosad of empresaDadosad" value="{{dadosad.idEmprad}}">{{dadosad.dsEndereco}} - {{dadosad.ufInstal}} - {{dadosad.coCep}}</option>
            </select>
          </div>

          <label class="text-danger"><strong> {{idEmprad}} </strong></label>

          <div class="form-outline mb-2">
            <h6> <label for="contatoTecnico" class="form-label text-primary">Contato responsável técnico</label></h6>
            <select id="contatoTecnico" class="form-select" formControlName="contatoTecnico" (ngModelChange)="selecionarContato()">
              <option>{{empresaDadosadSelecionado}} </option>
            </select>

            <label class="text-danger"><strong> {{contatoVerifica}} </strong></label>
          </div>

          <div hidden="true" class="form-outline mb-2">
            <h6> <label for="lcAtendimento" class="form-label text-primary"></label></h6>
            <select id="lcAtendimento" class="form-select" formControlName="lcAtendimento" (ngModelChange)="selecionarLocal()">
              <option [defaultSelected]="true">{{empresaDadosadLocalidade}}</option>
            </select>
          </div>
        </div>

          <div class="form-outline mb-2">
            <h6><label for="descricao" class="form-label text-primary">Descrição do chamado</label></h6>
            <textarea class="form-control" id="descricao" name="dsChamado" formControlName="dsChamado" rows="4"
              maxlength="255" (ngModelChange)="setCleanDescricao()"></textarea>
          </div>

          <label class="text-danger"><strong> {{descricao}} </strong></label>

          <div>
            <h6> <label for="noArquivo" class="form-label text-primary">Anexo</label></h6>
            <input class="form-control text-primary" id="noArquivo" type="file" (change)="setUploadFiles($event)"
              formControlName="noArquivo" multiple>
          </div>
          <div class="row d-flex justify-content-center text-center pt-4">
            <button type="submit" class="form-control btn btn-primary" [disabled]="showLoading"
              style="padding-left: 2.5rem; padding-right: 2.5rem;">
              Criar </button>
          </div>
          <form (ngSubmit)="cancelar()">
            <div class="row d-flex justify-content-center text-center pt-4">
              <button type="submit" class="btn btn-light" style="padding-left: 2.5rem; padding-right: 2.5rem;"
                [disabled]="showLoading">
                Cancelar</button>
            </div>
          </form>
        </form>
        <br>
        <div class="alert alert-success" role="alert" *ngIf="showMessageCreate">
          <h6 class="alert-heading">Chamado criado com sucesso!</h6>
        </div>
        <div class="alert alert-success" role="alert" *ngIf="showMessageRespondido">
          <h6 class="alert-heading">Chamado respondido com sucesso!</h6>
        </div>


        <!-- Responder chamado -->
        <form [formGroup]="chamadoForm" (ngSubmit)="responder()" *ngIf="showFormResponder">
          <div [hidden]="!telebrasiliaLogado">
            <h6> <label for="stProtocolo" class="form-label text-primary">Status do chamado </label></h6>
            <select id="stProtocolo" class="form-select" formControlName="stProtocolo" (ngModelChange)="setCleanStProtocolo()">
              <option value="Em execução">Em execução</option>
              <option value="Finalizado">Finalizado</option>
            </select>

            <label class="text-danger"><strong>{{status}}</strong></label>
            <div *ngIf=" atFechamento == 'Concedida'" class="alert alert-info" role="alert">
              <strong>Permissão para fechamento concedida!</strong>
            </div>

            <div *ngIf=" atFechamento == 'Não Concedida'" class="alert alert-danger" role="alert">
              <strong>Permissão para fechamento não concedida...</strong>
            </div>
          </div>

          <div [hidden]="telebrasiliaLogado">
            <div [hidden]="situacaoAtual != 'Resolvido'">
              <h6> <label for="stProtocolo" class="form-label text-primary">Finalizar chamado?</label></h6>
              <select id="stProtocolo" class="form-select" formControlName="stProtocolo" (ngModelChange)="setCleanStProtocolo()">
                <option value="Finalizado">Finalizar</option>
                <option value="Em execução">Não encerrar chamado</option>
              </select>
              <label class="text-danger"><strong>{{status}}</strong></label>
            </div>

            <div [hidden]="situacaoAtual === 'Resolvido'">
              <h6> <label for="scChamado" class="form-label text-primary">Situação do chamado</label></h6>
              <select id="scChamado" class="form-select" formControlName="scChamado" (ngModelChange)="setCleanScChamado()">
                <option value="Resolvido">Resolvido</option>
                <option value="Não resolvido">Não resolvido</option>
              </select>
              <label class="text-danger"><strong>{{status}}</strong></label>
            </div>

            <div [hidden]="atFechamento === 'Autorizar'">
              <h6> <label for="atFechamento" class="form-label text-primary">Autorizar fechamento do chamado pela equipe técnica?</label></h6>
                <select id="atFechamento" class="form-select" formControlName="atFechamento" (ngModelChange)="setCleanFechamento()">
                  <option value="Concedida">Autorizar</option>
                  <option value="Não concedida">Não autorizar</option>
                </select>
              <label class="text-danger"><strong>{{fechamento}}</strong></label>
                <div *ngIf="scFechamento == 'Permissão de fechamento solicitada'" class="alert alert-danger" role="alert">
                  <strong>Suporte está solicitando permissão para o fechamento do chamado!</strong>
                </div>
                <div *ngIf="scFechamento == 'Permissão de fechamento não solicitada'" class="alert alert-danger" role="alert">
                <strong>Suporte ainda não solicitou permissão para o fechamento do chamado...</strong>
                </div>
              <br>
              </div>
          </div>

          <div [hidden]="statusAtual === 'Aberto' || statusAtual === 'Em execução' && situacaoAtual === 'Resolvido' || !telebrasiliaLogado">
            <h6> <label for="scChamado" class="form-label text-primary">Situação do chamado</label></h6>
            <select id="scChamado" class="form-select" formControlName="scChamado" (ngModelChange)="setCleanScChamado()">
              <option value="Resolvido">Resolvido</option>
              <option value="Não resolvido">Não resolvido</option>
            </select>

            <div class="alert alert-danger" role="alert">
              <strong>Caso o cliente não marque a situação do chamado como RESOLVIDO após a resolução do chamado, enviar uma solicitação para que o cliente marque a situação do chamado como RESOLVIDO. Em caso de falta de interação fechar o chamado como RESOLVIDO.
              </strong>
            </div>
          </div>

          <div [hidden]="scFechamento === 'Permissão de fechamento solicitada'|| !telebrasiliaLogado">
            <h6> <label for="scFechamento" class="form-label text-primary">Solicitar autorização para fechamento de chamado?</label></h6>
            <select id="scFechamento" class="form-select" formControlName="scFechamento" (ngModelChange)="setCleanSolicitacao()">
              <option value="Permissão de fechamento solicitada">Solicitar autorização</option>
              <option value="Permissão de fechamento não solicitada">Não solicitar autorização</option>
            </select>
            <label class="text-danger"><strong>{{solisFecha}}</strong></label>
          </div>

          <div [hidden]="!telebrasiliaLogado || caIncidente != 'Não identificado' && caIncidente">
            <h6> <label for="caIncidente" class="form-label text-primary">Causa do incidente</label></h6>
            <select id="caIncidente" class="form-select" formControlName="caIncidente">
              <option>Falta de energia</option>
              <option>Rompimento de fibra</option>
              <option>Falha sistêmica</option>
              <option>Não identificado</option>
            </select>
            <label class="text-danger"><strong> {{incidente}} </strong></label>
          </div>

          <div class="form-outline mb-2">
            <h6> <label for="descricao" class="form-label text-primary">Descrição do chamado</label></h6>
            <textarea class="form-control" id="descricao" name="dsChamado" rows="4" maxlength="255"
              formControlName="dsChamado" (ngModelChange)="setCleanDescricao()"></textarea>
          </div>

          <label class="text-danger"><strong> {{descricao}} </strong></label>
          <div>
            <h6> <label for="arquivo" class="form-label text-primary">Anexo</label></h6>
            <input class="form-control text-primary" id="arquivo" type="file" (change)="setUploadFiles($event)"
              formControlName="noArquivo" multiple>
          </div>

          <div class="row d-flex justify-content-center text-center pt-4">
            <button type="submit" class="form-control btn btn-primary"
              style="padding-left: 2.5rem; padding-right: 2.5rem;">
              Responder</button>
          </div>

          <form (ngSubmit)="cancelar()">
            <div class="row d-flex justify-content-center text-center pt-4">
              <button type="submit" class="btn btn-light" style="padding-left: 2.5rem; padding-right: 2.5rem;">
                Cancelar</button>
            </div>
          </form>

          <input class="form-control" id="idChamado" name="idChamado" formControlName="idChamado" value="{{idChamado}}"
                 style="visibility: hidden;">


          <div style="visibility: hidden;">
            <input class="form-control" id="stProtocolo" name="stProtocolo" formControlName="stProtocolo"
              value="Em execução">
          </div>
        </form>
      </div>
    </div>
  </div>
</section>



<!-- Consultar chamado -->
<div class="container-fluid h-custom">
<section>
  <div class="row d-flex justify-content-center" *ngIf="listarChamado">
    <div class="col-md-10">
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">
            <div class="form-outline div5" >
              <form [formGroup]="chamadoForm" (change)="consultarChamado()">
                <input type="search" class="form-control" placeholder="Pesquisar" aria-label="Pesquisar"
                       formControlName="nuProtocolo" />
              </form>
            </div>
          </th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col">
            <form [formGroup]="chamadoForm" class="div5" (change)="consultarChamado()">
              <select type="submit" class="form-select" formControlName="caIncidente">
                <option [selected]="true">Filtrar</option>
                <option>Imputável</option>
                <option>Não imputável</option>
                <option value="ABERTO">Aberto</option>
                <option value="EM_EXECUCAO">Em execução</option>
                <option value="FINALIZADO">Finalizado</option>
              </select>
            </form>
          </th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
        </thead>
        <thead>
          <tr>
            <th scope="col">Protocolo</th>
            <th scope="col" class="text-center">CNPJ</th>
            <th scope="col" class="text-center">Localidade</th>
            <th scope="col" class="text-center">Equipe Técnica</th>
            <th scope="col" class="text-center">Tipo</th>
            <th scope="col" class="text-center">Status</th>
            <th scope="col" class="text-center">Situação</th>
            <th scope="col" class="text-center">Descrição</th>
            <th scope="col" class="text-center">Arquivo</th>
            <th scope="col" class="text-center">Abertura</th>
            <th scope="col" class="text-center">Em análise</th>
            <th scope="col" class="text-center">Fechamento</th>
            <th scope="col" class="text-center">Duração</th>
            <th scope="col" class="text-center">Incidente</th>
            <th scope="col" class="text-center">Autorização para fechamento</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let chamado of chamados">

            <td>
              <button [disabled]="isFinalizado(chamado)" type="submit" (click)="protocoloSelecionado(chamado)"
                class="btn btn-link">{{chamado.nuProtocolo}} </button>
            </td>
            <td>{{chamado.cnpj}}</td>
            <td class="text-center">
              <label class="div7">{{chamado.lcAtendimento}}</label>
            </td>
            <td class="text-center">
              <label class="div7">{{chamado.contatoTecnico}}</label>
            </td>
            <td class="text-center col-2">{{chamado.tpChamado}}</td>
            <td class="text-center col-2">{{chamado.stProtocolo}}</td>
            <td class="text-center col-3">{{chamado.scChamado}}</td>
            <td>
              <div id="div4" class="text-center">{{chamado.dsChamado}}</div>
            </td>
            <td class="text-center col-2">
              <button *ngFor="let file of chamado.files" [value]="file" type="submit"
                (click)="carregarArquivo(chamado.idProtocolo, file)" class="btn btn-link"> {{file}} </button>
            </td>

            <td class="text-center">
              <div *ngIf="!chamado.dtAbertura">
               <label>{{chamado.dataAbertura}}</label>
              </div>
              <div *ngIf="chamado.dtAbertura">
                {{chamado.dtAbertura}} {{chamado.hrExecucao}}
              </div>
            </td>

            <td class="text-center">
              <div *ngIf="chamado.dtExecucao">
                {{chamado.dtExecucao}} {{chamado.hrExecucao}}
              </div>
            </td>

            <td class="text-center">
              <div *ngIf="chamado.dtSolucao">
                {{chamado.dtSolucao}} {{chamado.hrExecucao}}
              </div>
            </td>

            <td class="text-center col-2">
              <div *ngIf="!chamado.duracaoChamado">
               <label>---</label>
              </div>
              <div class="div5" *ngIf="chamado.duracaoChamado">
                {{chamado.duracaoChamado}}
              </div>
            </td>

            <td class="text-center col-2">
              <div *ngIf="!chamado.caIncidente">
                <label>Não identificado</label>
              </div>
              <div *ngIf="chamado.caIncidente">
                {{chamado.caIncidente}}
              </div>
            </td>

            <td>
              <div class="At" *ngIf="chamado.scFechamento === 'Permissão de fechamento solicitada'">
                <div class="div5" *ngIf="chamado.atFechamento != 'Concedida'">
                 <label>Não concedida</label>
                </div>
              </div>

              <div class="noAt" *ngIf="chamado.scFechamento === 'Permissão de fechamento não solicitada'">
                <div class="div5" *ngIf="chamado.atFechamento != 'Concedida'">
                  <label>Não concedida</label>
                </div>
              </div>

              <div class="div5" *ngIf="!chamado.scFechamento || chamado.atFechamento === 'Concedida'">
                <div *ngIf="chamado.atFechamento === 'Concedida'">
                  {{chamado.atFechamento}}
                </div>
                <div *ngIf="chamado.atFechamento != 'Concedida'">
                  <label>Não concedida</label>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <nav aria-label="Paginas de chamados">
        <ul class="pagination">
          <li class="page-item">
            <button class="page-link" type="submit" [disabled]="numeroPagina <= 1"
              (click)="anterioPagina(1)">Anterior</button>
          </li>
          <li class="page-item active" aria-current="page">
            <span class="page-link">{{numeroPagina}}</span>
          </li>
          <li class="page-item">
            <a class="page-link" type="submit" (click)="proximaPagina(1)">Próxima</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</section>
</div>
<app-footer></app-footer>
